import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class PrivilegeGuardService {
  constructor(public router: Router, public authService: AuthService) { }

  // กำนหนด guard ในส่วนของการใช้งานกับ  canActivateChild ส่วนนี้จะใช้กับ path ของ route ย่อย
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // get privilege by menu
    const menuPrivilege = route.data.privilege;
    // get privilege by user login

    const userPrivilege = this.authService.getUser()['realm_access'].roles;
    var isProvilege = false;
    userPrivilege.forEach(element => {
      var check = menuPrivilege.includes(element)
      if (check) {
        isProvilege = true;
      }
    });

    if (!isProvilege) {
      this.router.navigate(['/404']);
    }
    return isProvilege;
    // compare menuPrivilege and userPrivilege
    // if (
    //   menuPrivilege &&
    //   userPrivilege &&
    //   ((menuPrivilege.broadcast === userPrivilege.broadcast &&
    //     menuPrivilege.broadcast) ||
    //     (menuPrivilege.transcribe === userPrivilege.transcribe &&
    //       menuPrivilege.transcribe) ||
    //     (menuPrivilege.create === userPrivilege.create &&
    //       menuPrivilege.create) ||
    //     (menuPrivilege.reserve === userPrivilege.reserve &&
    //       menuPrivilege.reserve) ||
    //     (menuPrivilege.approve === userPrivilege.approve &&
    //       menuPrivilege.approve) ||
    //     (menuPrivilege.bot === userPrivilege.bot && menuPrivilege.bot))
    // ) {


    // ตรวจสอบสิทธิ์การเข้าถึง
    // จะเข้าใช้งานได้เมื่อ คืนค่าเป็น true
    return true;
    // }
    // กรณีสิทธิ์การใช้งานไม่ผ่านให้ปฏิเสธการเข้าถึงทุกกรณี

    return false;
  }
}
