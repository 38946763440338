import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class AuthService {
  public currentUserSubject: BehaviorSubject<object>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(this.getUser());
    this.currentUser = this.currentUserSubject.asObservable();
  }
  // user login
  login(username: string, password: string) {
    return this.http.post(environment.API_URL + '/users/login', {
      username,
      password
    });
  }
  // user logout
  logout() {
    localStorage.removeItem('auth');
  }

  setUserAuth(user) {
    this.getUserByUsername(user).subscribe((res) => {
      localStorage.setItem('user_detail', JSON.stringify(res));
    })
    this.currentUserSubject.next(user);
    localStorage.setItem('auth', JSON.stringify(user));
  }
  // get user authenticate
  getUser() {
    return JSON.parse(localStorage.getItem('auth'));
  }

  getUserDetail() {
    return JSON.parse(localStorage.getItem('user_detail'));
  }

  getUserByUsername(user) {
    return this.http.get(environment.API_ACCOUNT + 'users/' + user.username + '/detail');
  }
}
